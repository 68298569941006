import EventEmitter from "eventemitter3";
import ExternalApplicationContext from "./ExternalApplicationContext";
import { getEnv } from "./config";

type CustomLocation = URL | Location;

export function getContextFromLocation(
  location: CustomLocation,
): ExternalApplicationContext {
  const tmpContext = new ExternalApplicationContext();
  let { search } = location;
  while (search.charAt(0) === "?") {
    search = search.slice(1);
  }
  const parsedSearch = new URLSearchParams(search);
  if (parsedSearch.has("service")) {
    tmpContext.service = parsedSearch.get("service");
    tmpContext.continue = parsedSearch.has("continue")
      ? parsedSearch.get("continue")
      : "/";
  } else if (parsedSearch.has("app_id")) {
    tmpContext.app_id = parsedSearch.get("app_id");
  }
  return tmpContext;
}

export function getReturnUrlFromContext(
  context: ExternalApplicationContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalObject?: any,
): string | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let queryObject = { ...additionalObject } as any;
  if (context && context.service) {
    const continueUrl = context.continue ? context.continue : "/";
    queryObject.continue = continueUrl;
    queryObject.service = context.service;
  }
  if (context && context.app_id) {
    queryObject.appid = context.app_id;
  }
  return `?${new URLSearchParams(queryObject).toString()}`;
}

export const EnvironmentChangedEvent = "_envChanged";
export const URLChangedEvent = "_urlChanged";

export default class Application {
  base = "";

  externalContextType: string = null;

  externalContext: ExternalApplicationContext = {};

  emitter = new EventEmitter();

  environment = "";

  env = getEnv();

  constructor(location: CustomLocation, base: string = window.location.origin) {
    this.setBase(base);
    this.setContextFromLocation(location);
  }

  // Environment
  setEnvironment(newEnv: string) {
    this.environment = newEnv;
    this.emitter.emit(EnvironmentChangedEvent, this.environment);
  }

  getEnvironment() {
    return this.environment;
  }

  getEmitter(): EventEmitter {
    return this.emitter;
  }

  // Context
  hasReturnUrl() {
    return !!this.externalContextType;
  }

  setContextFromLocation(location: CustomLocation) {
    this.externalContext = getContextFromLocation(location);
    if (this.externalContext.app_id) {
      this.externalContextType = "external";
    } else if (this.externalContext.service) {
      this.externalContextType = "local";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReturnUrl(additionalObject: any): string {
    return getReturnUrlFromContext(this.externalContext, additionalObject);
  }

  getContext(): ExternalApplicationContext {
    return this.externalContext;
  }

  setBase(newBase: string) {
    this.base = newBase;
  }

  createURLWithContext(location: string): CustomLocation {
    const url = new URL(location, this.base);
    const newURL = this.getLocationBasedOnContext(url);
    return newURL.location;
  }

  getCleanLocation(): URL {
    let currentLocation = new URL(window.location.href);
    currentLocation.searchParams.delete("app_id");
    currentLocation.searchParams.delete("service");
    currentLocation.searchParams.delete("continue");
    return currentLocation;
  }

  getLocationBasedOnContext(location: CustomLocation): {
    changed: boolean;
    location: CustomLocation;
    locationString: string;
  } {
    let { search } = location;
    let changed = false;
    while (search.charAt(0) === "?") {
      search = search.slice(1);
    }

    const parsedSearch = new URLSearchParams(search);
    if (
      (this.externalContext.app_id && !parsedSearch.has("app_id")) ||
      (this.externalContext.service && !parsedSearch.has("service"))
    ) {
      const newSearch = new URLSearchParams();
      if (this.externalContext.app_id) {
        newSearch.set("app_id", this.externalContext.app_id);
      }
      if (this.externalContext.service) {
        newSearch.set("service", this.externalContext.service);
        newSearch.set("continue", this.externalContext.continue);
      }
      const searchStringified = newSearch.toString();
      // eslint-disable-next-line no-param-reassign
      location.search = searchStringified;
      changed = true;
    }

    // create location string
    let locationString = `${location.origin}${location.pathname}`;
    if (location.search !== "") {
      locationString = `${locationString}?${location.search}`;
    }
    locationString += location.hash;
    return {
      changed,
      location,
      locationString,
    };
  }
}

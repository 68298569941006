import React, { Component } from "react";

import FeatherIcon from "../../../modules/icons/featherreact";

import context from "../../../context";

import sitetranslations from "../SiteTranslations";
import { EnvironmentChangedEvent } from "../../../context/application";

const appContext = context.application;
const appContextEmitter = appContext.getEmitter();

class BackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: appContext.getEnvironment(),
    };
    this.updateStateFromEnvironment =
      this.updateStateFromEnvironment.bind(this);
  }

  updateStateFromEnvironment() {
    this.setState({
      environment: appContext.getEnvironment(),
    });
  }

  componentDidMount() {
    console.log("Init back button");
    const that = this;
    that.UpdateAction = () => {
      that.forceUpdate();
    };
    appContextEmitter.on("go", that.UpdateAction);
    appContext.emitter.on(EnvironmentChangedEvent, () => {
      this.setState({
        environment: appContext.getEnvironment(),
      });
    });
    appContext.emitter.on(
      EnvironmentChangedEvent,
      this.updateStateFromEnvironment,
    );
  }

  componentWillUnmount() {
    const that = this;
    clearInterval(that.useLinkInterval);
    appContextEmitter.removeListener("go", that.UpdateAction);
    appContext.emitter.off(
      EnvironmentChangedEvent,
      this.updateStateFromEnvironment,
    );
  }

  render() {
    if (
      appContext.hasReturnUrl() &&
      (this.state.environment === "settings" ||
        this.state.environment === "authentication")
    ) {
      const returnUrl = `/app/return${appContext.getReturnUrl()}`;
      return (
        <div className="nav-item dropdown">
          <a href={returnUrl} className="btn btn-outline-secondary">
            <FeatherIcon
              icon="arrow-left"
              options={{ width: 20, height: 20 }}
            />
            <span className="d-none d-sm-inline">
              {sitetranslations.t("HEADER_GO_BACK_BUTTON")}
            </span>
          </a>
        </div>
      );
    }
    return null;
  }
}

export default BackCard;
